/* style.css*/
@keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(.3, .3, .3);
    }
   
    50% {
      opacity: 1;
    }
  }
   
  .zoomIn {
    animation-name: zoomIn;
  }
   
  @keyframes zoomOut {
    from {
      opacity: 1;
    }
   
    50% {
      opacity: 0;
      transform: scale3d(.3, .3, .3);
    }
   
    to {
      opacity: 0;
    }
  }
   
  .zoomOut {
    animation-name: zoomOut;
  }
   
  /* Not needed with the cssTransition helper */
   
  .animate {
    animation-duration: 800ms;
  }