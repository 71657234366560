.custom-tooltip {
  background: rgb(3, 33, 58);
  padding: 0px 10px;
  padding-top: 10px;
  border-radius: 3px;
  box-shadow: 0px 4px 7px 1px rgb(211 211 211);
  color: #fff;
  font-size: 15px;
  font-weight: bold;
}
.custom-tooltip ul {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

.custom-tooltip li:first-child p {
  padding-top: 10px;
}
.custom-tooltip li p {
  margin: 0px;
  padding-bottom: 10px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
.loader-p {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 111111111;
}
.loader-p:after {
  content: " ";
  background: transparent;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: transparent;
  -webkit-backdrop-filter: blur(1.5px);
  backdrop-filter: blur(1.5px);
  --webkit-backdrop-filter: blur(1.5px);
  background-color: rgba(0, 0, 0, 0.01);
  z-index: 1;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1em solid hsl(0deg 84% 63% / 20%);
  border-right: 1em solid hsl(0deg 84% 63% / 20%);
  border-bottom: 1em solid hsl(0deg 84% 63% / 20%);
  border-left: 1em solid #f05453;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
  z-index: 2;
}
.recharts-tooltip-wrapper {
  transform: none !important;
  left: inherit !important;
  right: 0px !important;
  top: -30px !important;
}

.customized-legend {
  display: flex;
  justify-content: center;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
